import React, { useRef, useState } from 'react';
import './SignUp.scss';
import { SignUpUser, CheckAlreadyExistNumber } from '../FilterProperty/SetServices';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import PhoneNumber from './PhoneNumber';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import i18n from "../../reduxstate/i18n.js";
function SignUp(props) {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [phoneNumberExists, setPhoneNumberExists] = useState(false);
  const [userName, setUserName] = useState('');
  const [show2, setShow2] = useState(false);
  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    userName: "",
    password: "",
    email: "",
    Confirmpassword: "",
    contact: "",
    registerFromWeb: true,
    isFirstTimeLogin: true,
    userDetails: [
      {
        userTypeID: 1
      }
    ]
  });

  const onChangeName = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const [phoneValidated, setPhoneValidated] = useState(false);

  const handleUserNameChange = (newUserName) => {
    if (!newUserName || userName == undefined) {
      return;
    }
    const strippedValue = newUserName.startsWith('+') ? newUserName.substring(1) : newUserName;
    setPhoneNumberExists(false);
    setFormData({ ...formData, userName: strippedValue, contact: strippedValue });
    setUserName(strippedValue);
    setPhoneValidated(!!strippedValue);
  };

  const checkPhoneNumber = async (phoneNumber) => {
    try {
      const response = await CheckAlreadyExistNumber(phoneNumber);
      return response;
    } catch (error) {
      console.error('Error checking phone number:', error);
      throw error;
    }
  };

  const userFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      if (!phoneValidated) {
        alert(t('PhoneNumberRequired'));
        return;
      }
      if (formData.password !== formData.Confirmpassword) {
        setPasswordMismatch(true);
        return;
      }
      if (formData.userName !== '') {
        const phoneNumberResponse = await checkPhoneNumber(formData.userName);
        if (!phoneNumberResponse) {
          const formDataWithoutConfirmPassword = { ...formData };
          delete formDataWithoutConfirmPassword.Confirmpassword;
          await SignUpUser(formDataWithoutConfirmPassword);
          Swal.fire({
            position: "center",
            icon: "success",
            title: t('RegisterSuccess'),
            showConfirmButton: false,
            timer: 1500
          });
          setFormData({
            name: "",
            userName: "",
            password: "",
            email: "",
            Confirmpassword: "",
            contact: "",
            registerFromWeb: true,
            isFirstTimeLogin: true,
            userDetails: [
              {
                userTypeID: 1
              }
            ]
          });
          setUserName('');
          setPasswordMismatch(false);
          formRef.current.reset();
          setShow2(true);
          props.onHide(true);
        }
        setPhoneNumberExists(phoneNumberResponse);
      }

      if (formData.password !== formData.Confirmpassword) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t('PasswordNotMatched'),
        });
        return;
      }
    } catch (error) {
      console.error('Error signing up user:', error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t('RegisterError'),
      });
      props.onHide(true);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Modal {...props} backdrop="static" keyboard={false}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="col-12 col-md-12 bsb-tpl-bg-lotion">
            <div className='card border-white bg-white rounded'>
              <div className="p-3 p-md-4 p-xl-5">
                <div className="row">
                  <div className="col-12">
                    <div className="mb-5 text-center">
                      <img src={`https://resx.mumtalikati.com/img/mumtalikatilogred.png`} alt="mumtalikati" loading="lazy" width={"150"} className="image-fluid" />
                      <h2 className='fs-1 fw-bold pt-2'>{t('CreateAccount')}</h2>
                      <p className='fs-3 fw-medium pt-2'>{t('ExperienceFullPower')}</p>
                    </div>
                  </div>
                </div>
                <form ref={formRef} onSubmit={userFormSubmit}>
                  <div className="row gy-3 gy-md-4 overflow-hidden">
                    <div className="col-12">
                      <div className="form-group">
                        <label  className='py-3 px-2 f16'>{t('FullName')}</label>
                        <input
                          type="text"
                          className={`form-control contactform f14  ${i18n.language === 'ar' ? 'placeholder-rtl' : 'placeholder-ltr'}`}
                          id="name"
                          placeholder={t('FullName')}
                          name="name"
                          value={formData.name}
                          onChange={onChangeName}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label  className='py-3 px-2 f16'>{t('PhoneNumber')}</label>
                        <PhoneNumber userName={userName} onUserNameChange={handleUserNameChange} />
                        {phoneNumberExists && <p className="text-danger">{t('PhoneNumberExists')}</p>}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label  className='py-3 px-2 f16'>{t('Email')}</label>
                        <input
                          type="email"
                          className={`form-control contactform f14  ${i18n.language === 'ar' ? 'placeholder-rtl' : 'placeholder-ltr'}`}
                          id="email"
                          placeholder={t('Email')}
                          name="email"
                          value={formData.email}
                          onChange={onChangeName}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label  className='py-3 px-2 f16'>{t('Password')}</label>
                        <input
                          type="password"
                          className={`form-control contactform f14  ${i18n.language === 'ar' ? 'placeholder-rtl' : 'placeholder-ltr'}`}
                          id="password"
                          placeholder={t('Password')}
                          name="password"
                          value={formData.password}
                          onChange={onChangeName}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label  className='py-3 px-2 f16'>{t('ConfirmPassword')}</label>
                        <input
                          type="password"
                          className={`form-control contactform f14  ${i18n.language === 'ar' ? 'placeholder-rtl' : 'placeholder-ltr'}`}
                          id="Confirmpassword"
                          placeholder={t('ConfirmPassword')}
                          name="Confirmpassword"
                          value={formData.Confirmpassword}
                          onChange={onChangeName}
                          required
                        />
                        {passwordMismatch && <p className="text-danger">{t('PasswordMismatch')}</p>}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-grid">
                        <button className="btn bsb-btn-xl btn-primary p-4" type="submit" style={{ fontSize: '1.5vh', backgroundColor: loader ? '#cccccc' : '#bb2525' }} disabled={loader}>
                          {t('RegisterNow')}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show2} onHide={() => setShow2(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="mb-5 text-center">
                <img src={`https://resx.mumtalikati.com/img/mumtalikati-Icon.png`} alt="mumtalikati" loading="lazy" width={"100"} className="image-fluid" />
                <p className='fs-2 fw-medium'>ممتلکاتی</p>
                <h2 className='fs-1 fw-bold pt-2'>{t('NowRegistered')}</h2>
                <p className='fs-4 fw-medium pt-2'>{t('DownloadApp')}</p>
                <div className="d-flex justify-content-evenly">
                  <a href='https://apps.apple.com/pk/app/mumtalikati/id1612677788'>
                    <img src={`https://resx.mumtalikati.com/img/app-store.svg`} alt="mumtalikati" loading="lazy" width={"150"} className="image-fluid" />
                  </a>
                  <a href='https://play.google.com/store/apps/details?id=com.alphai.mumtalikati.app_mumtalikati'>
                    <img src={`https://resx.mumtalikati.com/img/Playstore.svg`} alt="mumtalikati" loading="lazy" width={"150"} className="image-fluid" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShow2(false)}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SignUp;
