import axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_USER_BASE_URL,
});
api.interceptors.request.use(
  function (config) {
    const locale = localStorage.getItem('lang') ?? 'en-US'
    config.headers["Accept-Language"] = `${locale}`;
    config.headers["platform"] = "web";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default api;