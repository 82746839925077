
import { BrowserRouter, Routes, Route } from "react-router-dom";
import routes from "./Path";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<routes.Home />} />
        <Route path="/findAgent" element={<routes.FindAgent />} />
        <Route path="/:id" element={<routes.Home />} />
        <Route path="/PrivacyPolicy" element={<routes.PrivacyPolicy />} />
        <Route path="/ApplicationTerms" element={<routes.ApplicationTerms />} />
        <Route path="/UserTermsAndCondition" element={<routes.UsersTermsAndCondition />} />
        <Route path="/SignUp" element={<routes.SignUp />} />
        {/* <Route path="/property-Master-system" element={<routes.PropertyMangment />} /> */}
        <Route
          path="/to-Rent/:bedroom/:masterType/:city/:minValue/:maxValue"
          element={<routes.ListingPage />}
        />
        <Route
          path="/for-Sale/:bedroom/:masterType/:city/:minValue/:maxValue"
          element={<routes.ListingPage />}
        />
        <Route
          path="/to-Rent/:bedroom/:city/:minValue/:maxValue"
          element={<routes.ListingPage />}
        />
        <Route
          path="/for-Sale/:bedroom/:city/:minValue/:maxValue"
          element={<routes.ListingPage />}
        />
        <Route
          path="/to-Rent/:bedroom/:masterType/:subType/:city/:minValue/:maxValue"
          element={<routes.ListingPage />}
        />
        <Route
          path="/for-Sale/:bedroom/:masterType/:subType/:city/:minValue/:maxValue"
          element={<routes.ListingPage />}
        />
        <Route
          path="/property/:purpose/:bedroom/:masterType/:address"
          element={<routes.FullViewofCard />}
        />
        <Route
          path="/property/:purpose/:masterType/:address"
          element={<routes.FullViewofCard />}
        />
        <Route path="/Contactus" element={<routes.Contactpage />} />
        {/* <Route path="*" element={<routes.NotFound />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
