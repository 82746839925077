import React, { useState, useEffect, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import i18n from "../reduxstate/i18n.js";

const FaApple = lazy(() => import("react-icons/fa").then(module => ({ default: module.FaApple })));
const FaGooglePlay = lazy(() => import("react-icons/fa").then(module => ({ default: module.FaGooglePlay })));
const FaFacebookF = lazy(() => import("react-icons/fa").then(module => ({ default: module.FaFacebookF })));
const FaInstagram = lazy(() => import("react-icons/fa").then(module => ({ default: module.FaInstagram })));
const FaXTwitter = lazy(() => import("react-icons/fa6").then(module => ({ default: module.FaXTwitter })));
const FaLinkedinIn = lazy(() => import("react-icons/fa").then(module => ({ default: module.FaLinkedinIn })));
const FaYoutube = lazy(() => import("react-icons/fa").then(module => ({ default: module.FaYoutube })));
const IoIosCall = lazy(() => import("react-icons/io").then(module => ({ default: module.IoIosCall })));
const IoMdMail = lazy(() => import("react-icons/io").then(module => ({ default: module.IoMdMail })));
const FaLocationDot = lazy(() => import("react-icons/fa6").then(module => ({ default: module.FaLocationDot })));



const Footer = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleInput(event) {
    setEmail(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (email === "" || !/\S+@\S+\.\S+/.test(email)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
      alert(`Thank you for subscribing with ${email}`);
      setEmail("");
    }
  }

  const Discover = [
    { id: 2, text: t('Property for Rent in Oman'), href: "/to-Rent/All-bedroom/Oman/minValue=0/maxValue=175000",title:'Property for Rent in Oman' },
    { id: 3, text: t('Property for Sale in Oman'), href: "/for-Sale/All-bedroom/Oman/minValue=0/maxValue=175000",title:'Property for Sale in Oman' },
    { id: 4, text: t('Find Agent'), href: "/findAgent",title:'Find Real Estate Agents in Oman' },
    { id: 5, text: t('Contact Us'), href: "/Contactus",title:'Mumtalikati | Contact Us ' },
    // { id: 6, text: t('Careers'), href: "/" },
    // { id: 7, text: t('FAQs'), href: "/" }
  ];

  const QuickLinks = [
    { id: 1, text: t('Apartment for Rent in Oman'), href: "/to-Rent/All-bedroom/Residential/Building/Oman/minValue=0/maxValue=175000", title:"Apartment for Rent in Oman" },
    { id: 2, text: t('1 Bhk Apartment for Rent in Oman'), href: "/to-Rent/1-bedroom/Residential/Building/Oman/minValue=0/maxValue=175000", title:"" },
    { id: 3, text: t('2 Bhk Flat for Rent in Muscat, Oman'), href: "/to-Rent/2-bedroom/Residential/Building/Oman-In-Muscat-54/minValue=0/maxValue=175000", title:"" },
    { id: 5, text: t('Apartment for Rent in Muscat'), href: "/to-Rent/All-bedroom/Residential/Building/Oman-In-Muscat-54/minValue=0/maxValue=175000", title:"Apartment for Rent in Muscat in Oman" }
  ];

  const handleClick = (text) => {
    if (text === t('Find Agent')) {
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById("findAgent");
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
    else if (text === t('Contact Us')) {
      navigate('/Contactus');
    }
    else if (text === t('Property for Rent')) {
      const pathname = `/to-Rent/All-bedroom/Residential/Oman/minValue=0/maxValue=${175000}`;
      navigate({ pathname });
      window.location.reload();
    } else if (text === t('Property for Sale')) {
      const pathnameSale = `/for-Sale/All-bedroom/Residential/Oman/minValue=0/maxValue=${175000}`;
      navigate({ pathname: `${pathnameSale}` });
      window.location.reload();
    }
  };

  return (
    <footer className="footerbg">
      <div className="container py-3">
        <div className="row pt-4 pb-4 white">
          <div className={`col-md-4 ${isSmallScreen ? "order-2 ps-4" : ""}`}>
            <img
              width="140"
              height="40"
              src="https://resx.mumtalikati.com/img/mumtalikati-logo-white.png"
              srcSet="https://resx.mumtalikati.com/img/mumtalikati-logo-white.png"
              alt="mumtalikati"
              loading="lazy"
              className={`mobile-logo `}
            />
            <div className={`row pt-4 ${isSmallScreen ? "" : ""}`}>
              <div className={`col-md-6 font-family-poppins `}>
                <div className="f07 w-300 footer-font1 px-2 py-2 ">
                  {t('Need Live Support?')}
                </div>
                <div className=" f08 w-400 white footer-font1 px-2 py-2 ">
                  <Suspense fallback={<div>Loading...</div>}>
                    <IoIosCall style={{ height: "15px", width: "15px" }} />
                  </Suspense>
                  {t('+968 99160109')}
                </div>
                <div className="footermail d-flex f08 w-400 white footer-font1 px-2 py-2 ">
                  <span className="ps-2">
                    <Suspense fallback={<div>Loading...</div>}>
                      <IoMdMail style={{ height: "15px", width: "15px" }} />
                    </Suspense>
                  </span> <span className="ps-2">{t('Support@mumtalikati.com')}</span>
                </div>
                <div className="footermail d-flex f08 w-400 white footer-font1 px-2 py-2 ">
                  <span className="ps-2">
                    <Suspense fallback={<div>Loading...</div>}>
                      <FaLocationDot style={{ height: "15px", width: "15px" }} />
                    </Suspense>

                  </span> <span className="ps-2">GCC
                    U11, B360, South
                    Mawaleh,
                    Muscat, Oman</span>
                </div>
              </div>
              <div className="col-md-6  font-family-poppins">

              </div>
            </div>

          </div>
          <div
            className={`col-md-4 font-family-poppins white ps-4 ${isSmallScreen ? "order-1" : ""
              }`}
          >
            <div className="row ">
              <div className="col-md-7">
                <ul className={` list footerul ${i18n.language === 'ar' ? "" : "text-start"}`}>
                  <li className="f08 w-400 font-family-poppins pb-2">
                    {t('Quick Links')}
                  </li>
                  {QuickLinks.map((quickLinks) => (
                    <li
                      key={quickLinks.id}
                      className="f07 w-300  font-family-poppins py-2"
                    >
                      <a href={quickLinks.href} title={quickLinks.text} className="footer-a">
                        {" "}
                        {quickLinks.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-5">
                <ul className={` list footerul ${i18n.language === 'ar' ? "" : "text-start"}`}>
                  <li className="f08 w-400 font-family-poppins pb-2">
                    {t('Discover')}
                  </li>
                  {Discover.map((discover) => (
                    <li
                      key={discover.id}
                      className="f07 w-300 font-family-poppins py-2"
                    >
                      <Link className="footer-a" to={discover.href} title={discover.title}
                      //  onClick={() => handleClick(discover.text)}
                      >
                        {discover.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={`col-md-4 ps-4 ${isSmallScreen ? "order-3 ps-4 pb-4" : ""}`}>
            <div className=" font-family-poppins f08 white w-400">
              {t('Download Now')}
              <div className=" white pt-3">
                <div className="row">
                  <div className="col-md-6   font-family-poppins">
                    <div className="  footercard">
                      <div className={`d-flex p-2 ps-4 ${i18n.language === 'ar' ? 'gap-4' : ''}`}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <FaApple className="h-auto white" />
                        </Suspense>

                        <a
                          href="https://apps.apple.com/pk/app/mumtalikati/id1612677788"
                          className="footermail"
                        >
                          <div className=" white f07 w-300 ps-4 footer-font">
                            {t('Download on the')}
                            <br />
                            <span className=" f07 w-400 footer-font">
                              {t('Apple Store')}
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="pt-4 col-md-6 font-family-poppins footertoppadding">
                    <div className="  footercard">
                      <div className={`d-flex p-2 ps-4 ${i18n.language === 'ar' ? 'gap-4' : ''}`}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <FaGooglePlay className="h-auto white" />
                        </Suspense>

                        <a
                          href="https://play.google.com/store/apps/details?id=com.alphai.mumtalikati.app_mumtalikati"
                          className="footermail"
                        >
                          <div className=" white f07 w-300  ps-4 footer-font">
                            {t('Get it on')}
                            <br />
                            <span className=" f07 w-400 footer-font">
                              {t('Google Play')}
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </div>
            <div className="f08 w-400 white px-2 py-2 font-family-poppins">
              {t('Follow us on social media')}
            </div>
            <div className="white d-flex py-2">
              <a
                href="https://www.facebook.com/Mumtalikati"
                className="ps-2  white"
                aria-label="Facebook"
              >
                {" "}
                <Suspense fallback={<div>Loading...</div>}>
                  <FaFacebookF style={{ height: "15px", width: "15px" }} />
                </Suspense>

              </a>

              <a
                href="https://twitter.com/Mumtalikati_"
                className="ps-4 white"
                aria-label="Twitter"

              >
                {" "}
                <Suspense fallback={<div>Loading...</div>}>
                  <FaXTwitter style={{ height: "15px", width: "15px" }} />
                </Suspense>


              </a>
              <a
                href="https://www.instagram.com/mumtalikati/"
                className="ps-4 white"
                aria-label="LinkedIn"
              >
                {" "}
                <Suspense fallback={<div>Loading...</div>}>
                  <FaInstagram style={{ height: "15px", width: "15px" }} />
                </Suspense>

              </a>
              <a
                href="https://www.linkedin.com/company/mumtalikati/"
                className="ps-4 white"
                aria-label="linkedin"
              >
                {" "}
                <Suspense fallback={<div>Loading...</div>}>
                  <FaLinkedinIn
                    style={{ height: "15px", width: "15px" }}
                  />
                </Suspense>
                {" "}
              </a>
              <a
                href="https://www.youtube.com/@mumtalikatirealestateporta546"
                className="ps-4 white"
                aria-label="YouTube"
              >
                {" "}
                <Suspense fallback={<div>Loading...</div>}>
                  <FaYoutube
                    style={{ height: "15px", width: "15px" }}
                  />
                </Suspense>
                {" "}
              </a>
            </div>
          </div>
        </div>
        <div className="row border-top font-family-poppins white f07 w-300  pb-3">
          <div className="col-md-6 pt-3">
            {t('© Mumtalikati - All rights reserved')}
          </div>

          <div className="col-md-6 pt-3">
            <div className="text-end footertextstart">
              {/* {t('Privacy · Terms · Sitemap')} */}
              <a href="/PrivacyPolicy" className="text-white" >Privacy Policy</a>
              <a href="/ApplicationTerms" className="text-white">Terms & Condition</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
