
import {Routes, Route } from "react-router-dom";
import routes from "./Path";

const Router = () => {
  return (
      <Routes>
        <Route path="/" element={<routes.Home />} />
        <Route path="/findAgent" element={<routes.FindAgent />} />
        <Route path="/:id" element={<routes.Home />} />
        <Route path="/PrivacyPolicy" element={<routes.PrivacyPolicy />} />
        <Route path="/ApplicationTerms" element={<routes.ApplicationTerms />} />
        <Route path="/UserTermsAndCondition" element={<routes.UsersTermsAndCondition />} />
        <Route path="/SignUp" element={<routes.SignUp />} />
        {/* pms */}
        <Route path="/property-Master-system" element={<routes.PropertyMangment />} />
        <Route path="/property-Master-system/propertyMaster" element={<routes.PropertyMaster />} />
        <Route path="/property-Master-system/expense_management" element={<routes.ExpenseDetail />} />
        <Route path="/property-Master-system/tenant_management" element={<routes.TenantManagement />} />
        <Route path="/property-Master-system/smart-notifications" element={<routes.SmartNotification />} /> 
        <Route path="/property-Master-system/maintenance_requests" element={<routes.MaintenanceRequest />} />
        <Route path="/property-Master-system/rent_managements" element={<routes.RentManagement />} />

        
        <Route
          path="/to-Rent/:bedroom/:masterType/:city/:minValue/:maxValue"
          element={<routes.ListingPage />}
        />
        <Route
          path="/for-Sale/:bedroom/:masterType/:city/:minValue/:maxValue"
          element={<routes.ListingPage />}
        />
        <Route
          path="/to-Rent/:bedroom/:city/:minValue/:maxValue"
          element={<routes.ListingPage />}
        />
        <Route
          path="/for-Sale/:bedroom/:city/:minValue/:maxValue"
          element={<routes.ListingPage />}
        />
        <Route
          path="/to-Rent/:bedroom/:masterType/:subType/:city/:minValue/:maxValue"
          element={<routes.ListingPage />}
        />
        <Route
          path="/for-Sale/:bedroom/:masterType/:subType/:city/:minValue/:maxValue"
          element={<routes.ListingPage />}
        />
        <Route
          path="/property/:purpose/:bedroom/:masterType/:address"
          element={<routes.FullViewofCard />}
        />
        <Route
          path="/property/:purpose/:masterType/:address"
          element={<routes.FullViewofCard />}
        />
        <Route path="/Contactus" element={<routes.Contactpage />} />
        {/* <Route path="*" element={<routes.NotFound />} /> */}
      </Routes>
  );
};

export default Router;
