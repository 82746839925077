import { lazy } from "react";
const  PropertyMangment = lazy(() => import("../pages/newPms/MainPms"))
const UsersTermsAndCondition = lazy(() => import("../sharedcomponents/UsersTermsAndCondition"));
const ApplicationTerms = lazy(() => import("../sharedcomponents/ApplicationTerms"));
const ListingPage = lazy(() => import("../pages/propertyListings/ListingPage"));
const FullViewofCard = lazy(() => import("../pages/Fullviewcard/FullViewofCard"));
const PrivacyPolicy = lazy(() => import("../sharedcomponents/PrivacyPolicy"));
const Contactpage = lazy(() => import("../pages/Contactus/Contactpage"));
const NotFound = lazy(() => import("../sharedcomponents/NotFound"));
const FindAgent = lazy(() => import("../pages/findAgent/FindAgent"));
const SignUp = lazy(() => import("../pages/SignUp/SignUp"));
const Home = lazy(() => import("../pages/Home"));

const  routes = {Home,SignUp, PropertyMangment,UsersTermsAndCondition,ApplicationTerms,ListingPage,FullViewofCard,PrivacyPolicy,Contactpage,NotFound,FindAgent}

export default routes;