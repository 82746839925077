// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fav-btn {
  border: 1px solid #23262a;
  opacity: 80%;
  border-radius: 10px;
}
.marginbottom {
  margin-bottom: 0rem !important;
}
.card-a {
  color: #bb2525 !important;
}
.colpad {
  padding-left: 0rem;

}
.bgcard {
  background-color: #f7f7f8;
}
.viewcard-h{
    /* height: 80px; */
    border: none;
}
.cardshadow{
 box-shadow: 0px 1px 10px 0px #0000000A;
 border-radius: 16px;
/* box-shadow: 0px 3px 8px 0px #00000005; */
/* box-shadow: 0px 4px 8px -2px #00000005; */

}
.cardborder{
  border-color: transparent;
}
.breadcrumbs li{
  padding-inline:0rem;
margin: 0;
color: #000;
}
.mapicon{
 background-color: white;
 color:#bb2525 ;
 border-radius: 30px;
 border: none;
}

@media (max-width: 768px){
  .mapwidth {
      width: 100% !important;
  }}
  @media (max-width: 997px) {
     .col-md-8 .cardcol{
        display: block;
        width: auto;
        float: none;
        margin-left: auto;
        margin-right: auto;
    }
}
.cardfont{
  font-size: 0.7vw;
}
@media (max-width: 768px){
  .cardfont {
      font-size: 11px;
  }}`, "",{"version":3,"sources":["webpack://./src/Styling/FullViewCard.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,kBAAkB;;AAEpB;AACA;EACE,yBAAyB;AAC3B;AACA;IACI,kBAAkB;IAClB,YAAY;AAChB;AACA;CACC,sCAAsC;CACtC,mBAAmB;AACpB,2CAA2C;AAC3C,4CAA4C;;AAE5C;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,mBAAmB;AACrB,SAAS;AACT,WAAW;AACX;AACA;CACC,uBAAuB;CACvB,cAAc;CACd,mBAAmB;CACnB,YAAY;AACb;;AAEA;EACE;MACI,sBAAsB;EAC1B,CAAC;EACD;KACG;QACG,cAAc;QACd,WAAW;QACX,WAAW;QACX,iBAAiB;QACjB,kBAAkB;IACtB;AACJ;AACA;EACE,gBAAgB;AAClB;AACA;EACE;MACI,eAAe;EACnB,CAAC","sourcesContent":[".fav-btn {\r\n  border: 1px solid #23262a;\r\n  opacity: 80%;\r\n  border-radius: 10px;\r\n}\r\n.marginbottom {\r\n  margin-bottom: 0rem !important;\r\n}\r\n.card-a {\r\n  color: #bb2525 !important;\r\n}\r\n.colpad {\r\n  padding-left: 0rem;\r\n\r\n}\r\n.bgcard {\r\n  background-color: #f7f7f8;\r\n}\r\n.viewcard-h{\r\n    /* height: 80px; */\r\n    border: none;\r\n}\r\n.cardshadow{\r\n box-shadow: 0px 1px 10px 0px #0000000A;\r\n border-radius: 16px;\r\n/* box-shadow: 0px 3px 8px 0px #00000005; */\r\n/* box-shadow: 0px 4px 8px -2px #00000005; */\r\n\r\n}\r\n.cardborder{\r\n  border-color: transparent;\r\n}\r\n.breadcrumbs li{\r\n  padding-inline:0rem;\r\nmargin: 0;\r\ncolor: #000;\r\n}\r\n.mapicon{\r\n background-color: white;\r\n color:#bb2525 ;\r\n border-radius: 30px;\r\n border: none;\r\n}\r\n\r\n@media (max-width: 768px){\r\n  .mapwidth {\r\n      width: 100% !important;\r\n  }}\r\n  @media (max-width: 997px) {\r\n     .col-md-8 .cardcol{\r\n        display: block;\r\n        width: auto;\r\n        float: none;\r\n        margin-left: auto;\r\n        margin-right: auto;\r\n    }\r\n}\r\n.cardfont{\r\n  font-size: 0.7vw;\r\n}\r\n@media (max-width: 768px){\r\n  .cardfont {\r\n      font-size: 11px;\r\n  }}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
